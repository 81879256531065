import React, { Fragment, useState, useEffect } from 'react';
import { Link, graphql } from 'gatsby';

import {
    IntroHolder,
    TwoColumns,
    Content,
} from '@bahaide/shared/components/InnerPage';

import InnerPage from '../../components/InnerPage';

const slugify = require('../../util/slugify').default;

function BahaiKennenlernen({ data }) {
    const initialData = data.allLokaleGemeindenCsv.edges;
    const [query, setQuery] = useState('');
    const [locales, setData] = useState({ data: initialData, isFiltered: false });
    const [userCoords, setUserCoords] = useState({ userCoordinates: null });

    function _handleSubmit(e) {
        e.preventDefault();
        _handleSearch();
        setQuery('');
    }

    function _handleSearch() {
        const parsedQuery = slugify(query);
        const filteredLocalities = initialData.filter(({ node }) => node.fields.searchFields.city.includes(parsedQuery) || node.fields.searchFields.state.includes(parsedQuery));
        setData({ data: filteredLocalities, isFiltered: true });
    }

    function _resetSearch(e) {
        setData({ data: initialData, isFiltered: false });
        e.preventDefault();
    }

    return (
        <InnerPage
            title="Bahá’í kennenlernen"
            description="Gemeinsam ein fruchtbares Umfeld schaffen"
        >
            <IntroHolder
                title="Bahá’í kennenlernen"
                subtitle="Gemeinsam ein fruchtbares Umfeld schaffen"
                picture={data.introImage}
                breadcrumbs={{
                    'current-item': 'Bahá’í kennenlernen',
                }}
            >
            </IntroHolder>

            <TwoColumns>
                <Content>
                    <p>
                    Gemeinsam mit Freunden und Menschen aus ihrer Umgebung sind Bahá’í überall in Deutschland bestrebt, ein Umfeld für Kinder, Jugendliche und Erwachsene zu schaffen, welches das geistige und gesellschaftliche Wohlergehen fördert.
                    </p>
                    <div><p>Die Bahá’í-Gemeinde in Deutschland ist in mehr als 150 Ortschaften vertreten. Hier finden Sie E-Mail-Adressen und Webseiten der Gemeinden.</p></div>
                    <div style={{ marginTop: -40, marginBottom: 50 }}>
                        <form onSubmit={_handleSubmit}>
                            <input type="text" value={query} onChange={(e) => setQuery(e.target.value)} />
                            <div style={{
                                display: "flex",
                                flexDirection: "row"
                            }}>
                                <input type="submit" role="button" value="Suchen" style={{ marginRight: 10 }} />
                                {locales.isFiltered &&
                                    <button onClick={_resetSearch}><i className="fa fa-repeat" /></button>
                                }
                            </div>
                        </form>
                    </div>
                    {!locales.data.length &&
                        <p>Leider konnten wir keine Ortschaften finden.</p>
                    }
                    {/* Split communities into states */}
                    {[...new Set(locales.data.map(({ node }) => node.state))].sort().map((state, i) => (
                        <Fragment key={i}>
                            <h2>{state}</h2>
                            <ul className="local-communities">
                                {locales.data.filter(({ node }) => node.state == state).map(({ node }, i) => (
                                    <li key={i}>
                                        {
                                            (
                                                <Link to={"/"+ node.fields.slug}>
                                                    {node.city}
                                                </Link>
                                            )
                                        }
                                    </li>
                                ))}
                            </ul>
                        </Fragment>
                    ))}
                </Content>
            </TwoColumns>
        </InnerPage>
    );
}

export default BahaiKennenlernen;

// Only list those communities that already have a @bahai.de email address
export const pageQuery = graphql`
    query {
        allLokaleGemeindenCsv(sort: {
            fields: [city]
            order: ASC
        }, filter: {
            use_email: {
                eq: "yes"
            }
        }) {
            edges {
                node {
                    city
                    state
                    website
                    use_website
                    email
                    facebook
                    lat
                    lon
                    fields {
                        slug
                        searchFields {
                            city
                            state
                        }
                    }
                }
            }
        }
        introImage: file(
            relativePath: {
                eq: "bahai-kennenlernen-feature-e1499439811331.jpg"
            }
        ) {
            ...fluidImage
        }
    }

`;
