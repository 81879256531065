// From: https://gist.github.com/hagemann/382adfc57adbd5af078dc93feef01fe1

exports.default = function(string) {
    return string.toString().toLowerCase()
        .replace(/\s+/g, '-') // Replace spaces with -
        .replace(/ä/g, 'ae')
        .replace(/ö/g, 'oe')
        .replace(/ü/g, 'ue')
        .replace(/ß/g, 'ss')
}
